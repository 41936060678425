import React, { useEffect, useRef } from 'react';
import './App.css';
import cat from '../src/assets/cat.png'
import dog from '../src/assets/dog.png';
import fromicon from '../src/assets/form-icon.svg';
import logo from '../src/assets/logo.svg';
import nameicon from '../src/assets/name.svg';
import phoneicon2 from '../src/assets/phone.svg';
import zipicon from '../src/assets/zip.svg';
import nificon from '../src/assets/nif.svg';
import emailicon from '../src/assets/email.svg';
import arrowcta from '../src/assets/arrow-cta.svg';
import { useState } from 'react';
import Fidelidadepet from './Fidelidadepet';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Footer from './Footer';
import Service from './Service';
import { lightGreen } from '@mui/material/colors';

function App() {
const nomeinp = useRef();
const emailinp = useRef();
const nifinp = useRef();
const zipinp = useRef();
const mobileinp = useRef();
  const [formstep, setFromstep] = useState(1);
 
const [formdata, setFormdata] = useState(
  {
    pettype:'GATO',
    agerange:<>Entre <span className='text-themered text-5xl px-2'> 2 </span> e <span className='text-themered text-5xl px-2'> 6 </span> meses</>,
    petage:'Entre 2 e 6 meses',
    name:'',
email:'',
nif:'',
zip:'',
phone:''
  }
);

const marks = [
  
];
function valuetext(value) {
   return `${value}`;
}
const handleSliderChange = (event, newValue) => {
  let oldData={...formdata}
  oldData["agerange"] =getagerange(newValue);
  oldData["petage"] =getpetage(newValue);
  setFormdata(oldData);
};

const getagerange = (newValue) =>{
  var agrernge;
switch(newValue){
case 1:
  agrernge = <>Entre <span className='text-themered text-5xl px-2'> 2 </span> e <span className='text-themered text-5xl px-2'> 6 </span> meses</>
break;
case 2:
  agrernge = <>Entre <span className='text-themered text-5xl px-2'> 6 </span> meses e <span className='text-themered text-5xl px-2'> 1,5 </span> anos</>
break;
case 3:
  agrernge = <>Entre <span className='text-themered text-5xl px-2'> 1,5 </span> anos e <span className='text-themered text-5xl px-2'> 2 </span> anos</>
break;
case 4:
  agrernge = <>Entre <span className='text-themered text-5xl px-2'> 2 </span> anos e <span className='text-themered text-5xl px-2'> 4 </span> anos</>
break;
case 5:
  agrernge = <>Entre <span className='text-themered text-5xl px-2'> 4 </span> anos e <span className='text-themered text-5xl px-2'> 7 </span> anos</>
break;
case 6:
  agrernge = <>Entre <span className='text-themered text-5xl px-2'> 1,5 </span> anos e <span className='text-themered text-5xl px-2'> 2 </span> anos</>
break;
default:
  agrernge = <>Entre <span className='text-themered text-5xl px-2'> 7 </span> anos e <span className='text-themered text-5xl px-2'> 8 </span> anos</>
}
  return agrernge;
}


const getpetage = (newValue) =>{
  var agrernge;
switch(newValue){
case 1:
  agrernge = "Entre 2 e 6 meses"
break;
case 2:
  agrernge = "Entre 6 meses e 1,5 anos"
break;
case 3:
  agrernge = "Entre 1,5 anos e 2 anos"
break;
case 4:
  agrernge = "Entre 2 anos e 4 anos"
break;
case 5:
  agrernge = "Entre 4 anos e 7 anos"
break;
case 6:
  agrernge = "Entre 1,5 anos e 2 anos"
break;
default:
  agrernge = "Entre 7 anos e 8 anos"
}
  return agrernge;
}

const updatepettype = (pettype) =>{
  let oldData={...formdata}
  oldData["pettype"] =pettype;
    setFormdata(oldData);
}

const validateform = (e) =>{
  let oldData={...formdata}
  let inp = e.target.name;
  let inpvalue =  e.target.value.slice(0, e.target.maxLength);
  oldData[inp] =inpvalue;
    setFormdata(oldData);
}



const validatezip = (e) => {
  let value = e.target.value.replace(/ /g, ""); // Remove existing hyphens
  let inputNumbersOnly = value.replace(/\D/g, "");
  if (inputNumbersOnly.length > 8) {
    inputNumbersOnly = inputNumbersOnly.substr(0, 8);
  }
  const splits = inputNumbersOnly.match(/.{1,4}/g);
   let spacedNumber = "";
  if (splits) {
    spacedNumber = splits.join("-");
  }
  let oldData={...formdata}
  let inp = e.target.name;
  oldData[inp] =spacedNumber;
    setFormdata(oldData);
};
useEffect(()=>{

});

const checkempty = (inp) =>{
  if(inp.current.value == '')
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}

const checknumberonly = (inp) =>{
  if(!isNaN(inp.current.value))
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}

const checkemail = (inp) =>{
  const errorMessage = document.getElementById('emaillbl'); 
  var emailregex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if(!emailregex.test(inp.current.value))
    {
      errorMessage.textContent = 'Introduza um endereço de e-mail válido.'; 

      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      errorMessage.textContent = ''; 
      inp.current.classList.remove("shadowinput");
    return true;
    }
}

const checkmobile = (inp,length) =>{
  const errorMessage = document.getElementById('mobilelbl');
  let value = inp.current.value;
  value = value.replace(/\D/g, '');
let iserror = false;
  if (value.length === 9 && value[0] === '9') {
    iserror = false;
    errorMessage.textContent = ''; // Clear the error message
  }else if (value.length === 9 && value[0] !== '9') {
    errorMessage.textContent = 'O número de telefone deve começar com 9.';
    iserror = true;
  } else if (value.length < 9) {
    errorMessage.textContent = 'O número de telefone deve ter exatamente 9 dígitos.';
    iserror = true;
  }
  if(iserror)
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}


const checkpostalcode = (inp, length) =>{
  const errorMessage = document.getElementById("ziplbl");
  let iserror = false;
  if(inp.current.value.length != length)
    {
      errorMessage.textContent = 'O código postal deve ser exactamente 7 dígitos.';
      iserror = true;
    }else{
      iserror = false;
      errorMessage.textContent = '';
    }

  if(iserror)
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}


const checknif = (inp, length) =>{
  const errorMessage = document.getElementById("niflbl");
  let iserror = false;
  if(inp.current.value.length != length)
    {
      errorMessage.textContent = 'O Nif deve ter exatamente 9 dígitos.';
      iserror = true;
    }else{
      iserror = false;
      errorMessage.textContent = '';
    }

  if(iserror)
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}

const submitform = () => {
  if(formstep == 2){
if(
  checkempty(nomeinp) == true
  &&  checkemail(emailinp) == true
  && checknif(nifinp, 9) == true
  && checkpostalcode(zipinp, 8) == true
  && checkmobile(mobileinp,9) == true
)
 {
  senddata();
}
  }
}


const senddata = async  () => {
  const data = {
  nif: formdata.nif,
  name: formdata.name,
  email: formdata.email,
  phone: formdata.phone,
  zip: formdata.zip,
  argan: "",
  pettype: formdata.pettype,
  petage:formdata.petage,
  licenseplate: "",
  observations: "",
  source: 1,
  scheduletype: 0,
  isClient: true,
  family: 0,
  birthdate: "",
  date: new Date().toISOString()
  }
  const response = await fetch(
    //'https://localhost:7261/Leads/add', 
    'http://fidelidadeapi.quickflowai.com/Leads/add',
    {
    method: 'POST',
    body: JSON.stringify(data),  
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const result = await response.json();
  console.log(response);
   if(response.status == 200){
    if (result.value == "Duplicate lead"){
      setFromstep(5)
    }else{
      const today = new Date();
      const day = today.getDay();
      if (day >= 1 && day <= 4){
        setFromstep(3)
      }else{
        setFromstep(4)
      }
    }

     }
}


  return (
    <>
 <div className="w-full flex flex-col lg:flex-row">
    <div className={` w-full  flex flex-col justify-between bg-[url('../src/assets/hero-desktop.jpg')] bg-no-repeat bg-cover bg-center pt-4 lg:pt-12 pl-6 lg:pl-24 text-white`}>
    <div className='max-md:mb-12'>
    <img src={logo} alt='' className='max-md:w-[200px]' />
     <p className='font-bebasneuepro text-xl lg:text-3xl font-bold pt-2'>SEGURO DE SAÚDE ANIMAL</p>
    </div>
    
    <div className='mb-4 lg:mb-16'>
      <h1 className='font-bebasneuepro text-4xl lg:text-7xl font-bold mb-1 lg:mb-1 uppercase'>RECEBA ATÉ 250€ <br />
      Em vouchers
      </h1>
      <p className='mb-6 font-bold text-2xl lg:text-5xl font-bebasneuepro uppercase' >+25 EM SERVIÇOS FIXO.PT
      </p>
      <span className='text-themered border border-themered px-3 py-2 text-xl lg:text-3xl uppercase text-center font-bebasneuepro hidden'>Código: <span className='font-bold'>VERAO</span>
      </span>
    </div>
    </div>
    <div className='w-full  bg-themered lg:px-16 lg:pb-16'>
      <div className='text-center '>
      <img src={fromicon} alt='' className='max-md:hidden m-auto' />
      </div>
      
      <p className={`text-white text-center font-bebasneuepro text-3xl lg:text-5xl mb-1 font-semibold mt-10 lg:mt-5 lg:mb-5 ${formstep == 3 ? 'hidden' :'block'}`}>PEÇA A SUA SIMULAÇÃO</p>
<div className={`bg-white rounded-xl mx-4 pt-2 px-4 lg:px-12 mb-3 ${formstep > 2 ? 'hidden' :'block'}`}>
  <div className={` ${formstep == 1 ? 'block' :'hidden'}`}>
  <p className='font-bebasneuepro font-semibold text-center text-2xl lg:text-3xl py-2 uppercase lg:mb-2'>O seu animal é?</p>

<div className='flex gap-8 justify-center  mb-2  font-bebasneuepro font-semibold text-[#777] text-md lg:text-2xl lg:px-8'>
  <button className={`w-full px-6 py-3 lg:py-4 flex justify-center items-center border-2 gap-4  rounded-xl uppercase ${formdata["pettype"] == "CÃO" ? "border-themered bg-[#f9c8cb]" : "border-[#eaeaea] bg-[#f7f5f5]"}`} onClick={()=>updatepettype('CÃO')}><img src={dog} className='w-[60px] lg:w-[80px]'  /> Cão</button>
  <button className={`w-full px-6 py-3 lg:py-4 flex justify-center items-center gap-4 border-2  rounded-xl uppercase ${formdata["pettype"] == "GATO" ? "border-themered bg-[#f9c8cb]" : "border-[#eaeaea] bg-[#f7f5f5]"}`} onClick={()=>updatepettype('GATO')}><img src={cat} className='w-[60px]  lg:w-[80px]'  /> Gato</button>
</div>
<p className='font-bebasneuepro font-semibold text-center text-xl lg:text-2xl py-1 uppercase'>Que idade tem o seu animal?</p>
<p className='  text-[#aaaaaa] text-center text-xs lg:text-md py-1'>Seguro válido para pets com idade igual ou inferior a 8 anos</p>

<p className=' flex items-center justify-center text-center text-[#777] text-2xl lg:text-3xl my-4'>{formdata["agerange"]}</p>
<div className='py-4'>
<Box sx={{ width: '100%' }}>
      <Slider
        aria-label="age range"
        defaultValue={0}
        getAriaValueText={valuetext}
        step={1}
        marks={marks}
        min={1}
  max={7}
        onChange={handleSliderChange}
           
      />
    </Box>
</div>


<p className='text-xs lg:text-md text-[#212121]'>
<strong>Por que precisamos dos seus dados?</strong><br />
Os dados pessoais por si acima facultados serão tratados <strong>para posterior contacto telefónico com a finalidade de realizar a simulação</strong> e para fazer o acompanhamento comercial da mesma, com fundamento no desenvolvimento de diligências pré-contratuais a pedido do titular e no interesse legítimo do desenvolvimento da atividade comercial da Fidelidade.
</p>

<p className='text-themered text-xs leading-1 py-4'><a href='https://www.fidelidade.pt/PT/protecao-dados/Documents/Politica_de_Privacidade_e_Protecao_de_Dados_Pessoais%E2%80%93FIDELIDADE.pdf' className='underline text-themered' target='_blank' >Consulte aqui, informação mais detalhada sobre proteção e tratamento dos seus dados pela Fidelidade.</a></p>

  </div>
  
 <div className={` ${formstep === 2 ? 'block' :'hidden'}`}>
 <p className='font-bebasneuepro font-semibold text-center text-2xl lg:text-3xl py-2 uppercase lg:mb-2'>Preencha o formulário</p>
 <div className='px-5 relative mb-3'>
 <input type='text' ref={nomeinp} name="name" value={formdata.name} onChange={validateform}  className={`block w-full py-4 pl-12 pr-8   rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal `} placeholder='Nome'   maxLength={100} /> 
 <span className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <img src={nameicon} alt='' className='absolute top-[50%] -translate-y-[50%] left-9'/>
 </div>

 <div className='px-5 relative mb-3'>
 <input type='text' ref={emailinp} name="email" value={formdata.email} onChange={validateform}  className={`block w-full py-4 pl-12 pr-8   rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal `} placeholder='Email'   maxLength={100} /> 
 <span id="emaillbl" className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <img src={emailicon} alt='' className='absolute top-[50%] -translate-y-[50%] left-9'/>
 </div>

 <div className='px-5 relative mb-3'>
 <input type='number' inputmode="numeric"  ref={nifinp} name="nif" value={formdata.nif} onChange={validateform}  className={`block w-full py-4 pl-12 pr-8   rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal `} placeholder='NIF'  maxLength={9} /> 
 <span id="niflbl" className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <img src={nificon} alt='' className='absolute top-[50%] -translate-y-[50%] left-9'/>
 </div>

<div className='flex flex-col lg:flex-row mb-1'>
<div className='w-full px-5 relative mb-3'>
 <input type='text' inputmode="numeric"  ref={zipinp} name="zip" value={formdata.zip} onChange={validatezip}  className={`block w-full py-4 pl-12 pr-8   rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal `} placeholder='Código Postal'   maxLength={8} /> 
 <span id="ziplbl" className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <img src={zipicon} alt='' className='absolute top-[50%] -translate-y-[50%] left-9'/>
 </div>

 <div className='w-full px-5 relative mb-3'>
 <input type='number' inputmode="numeric"  name="phone" ref={mobileinp} value={formdata.phone} onChange={validateform}  className={`block w-full py-4 pl-12 pr-8   rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal `} placeholder='Nº Telemóvel'   maxLength={9} /> 
 <span id="mobilelbl" className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <img src={phoneicon2} alt='' className='absolute top-[50%] -translate-y-[50%] left-9'/>
 </div>

  </div>

 </div>


<div className='flex justify-between '>
<div className='' ><button onClick={()=>setFromstep(1)} className={` bg-[#9fbe80] hover:bg-[#86ad5e] rounded-full w-[60px] h-[60px] flex justify-center items-center  ${formstep === 1 ? 'hidden lg:invisible' : ''} `}><img src={arrowcta} alt="" className='w-[15px] rotate-180' /></button></div>
<div className=' flex justify-center pb-4 '><button onClick={()=>{setFromstep(2); submitform();}}  className='flex items-center justify-between w-[250px] lg:w-[500px] bg-[#9fbe80] hover:bg-[#86ad5e] text-center rounded-full px-4 py-3 text-white font-bebasneuepro font-semibold text-3xl  '><span></span> <span> {formstep == 1 ? 'CONTINUAR' : 'PEDIR SIMULAÇÃO'}  </span> <img src={arrowcta} alt="" className='w-[15px]  lg:mr-5' /></button></div>
<div className=''></div>
</div>




<div className='flex justify-center gap-2 pb-4'>
  <span className={`block rounded-full border w-[12px] h-[12px]  ${formstep === 1 ? 'bg-[#eee]' :'bg-[#eee]'}`}></span>
  <span className={`block rounded-full border w-[12px] h-[12px]  ${formstep === 2 ? 'bg-[#eee]' :'bg-[#fff] border-[#ccc]'}`}></span>
</div>



</div>


<div className={`max-w-[90%] lg:max-w-[65%] pb-48 pt-12 mx-auto ${formstep == 3 ? 'block' :'hidden'} text-white text-center font-bebasneuepro text-2xl lg:text-3xl `}>
<p className={`text-white text-center font-bebasneuepro text-4xl lg:text-5xl mb-3 font-semibold mt-10 lg:mt-5 lg:mb-5 `}>
  Olá {formdata["name"]}!</p>
<p className='uppercase mb-3'>Obrigado pelo seu pedido de simulação.</p>
<p className='uppercase mb-3'>Em breve, a nossa equipa entrará em contacto consigo para lhe apresentar a nossa melhor proposta.</p>
<p className='uppercase  mb-3'>Enquanto isso, pode explorar mais sobre os nossos produtos e serviços no nosso site: <a href='https://www.fidelidade.pt' target='_blank'>www.fidelidade.pt</a>.
</p>
<p className='uppercase mb-3'>
Obrigado<br />
<strong>Fidelidade</strong>
</p>
</div>
<div className={`max-w-[90%] lg:max-w-[65%] pb-48 pt-12 mx-auto ${formstep == 4 ? 'block' :'hidden'} text-white text-center font-bebasneuepro text-2xl lg:text-3xl `}>
<p className={`text-white text-center font-bebasneuepro text-4xl lg:text-5xl mb-3 font-semibold mt-10 lg:mt-5 lg:mb-5 `}>
  Olá  {formdata["name"]}!</p>
<p className='uppercase mb-3'>Obrigado pelo seu pedido de simulação.</p>
<p className='uppercase mb-3'>Em breve, a nossa equipa entrará em contacto consigo para lhe apresentar a nossa melhor proposta.</p>
<p className='uppercase mb-3'>Enquanto isso, pode explorar mais sobre os nossos produtos e serviços no nosso site: www.fidelidade.pt.</p>
<p className='uppercase mb-3'>Obrigado<br />
<strong>Fidelidade</strong>
</p>
</div>

<div className={`max-w-[90%] lg:max-w-[65%] pb-48 pt-12 mx-auto ${formstep == 5 ? 'block' :'hidden'} text-white text-center font-bebasneuepro text-2xl lg:text-3xl `}>
<p className={`text-white text-center font-bebasneuepro text-4xl lg:text-5xl mb-3 font-semibold mt-10 lg:mt-5 lg:mb-5 `}>
Obrigado  {formdata["name"]}!</p>
<p className='uppercase mb-3'>Verificamos que já tinha pedido um contacto anteriormente.</p>
<p className='uppercase mb-3'>A nossa equipa entrará em contacto consigo o mais breve possível para ajudar a esclarecer.</p>
<p className='uppercase mb-3'>Enquanto isso, pode explorar mais sobre os nossos produtos e serviços em<br/>
<a href='https://www.fidelidade.pt' target='_blank'>www.fidelidade.pt</a>.
</p>
</div>
      </div>
    </div>
<Service />
    <Fidelidadepet />
    <Footer />
    </>
   
  );
}

export default App;
