import React from 'react'
import arrowcta from '../src/assets/arrow-cta.svg';
import homeicon from '../src/assets/home.png';
import animal from '../src/assets/animal.png';
import messagem from '../src/assets/messagem.png';
import empresas from '../src/assets/empresas.png';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

const scrollToTop = () => {
  scroll.scrollToTop();
};
function Service() {
  return (
    <div className='bg-[#f0f0f0] py-12 px-10 lg:px-0'>
         <h1 className="text-5xl font-bold text-themered mb-2 text-center  uppercase font-bebasneuepro">Serviços FIXO.PT</h1>
         <p className="text-md lg:text-lg text-[#212121] font-semibold mb-10 text-center">
          Descubra todos os serviços disponíveis no FIXO para que tenha mais tempo livre para si.
        </p>
       
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:max-w-6xl mx-auto  ">
          {/* Card 1 */}
          <div className="bg-white p-6  rounded-lg shadow-lg relative text-center max-md:mb-8">
            <div className="w-[62px] h-[62px] absolute -top-4 right-2 font-bebasneuepro bg-themered text-white uppercase text-sm font-bold  rounded-full text-center flex flex-col items-center justify-center leading-none">
              <span>Oferta</span> <span className='text-3xl leading-[0.7em]'>25€</span>
            </div>
            <img src={homeicon} alt="Casa" className=" mx-auto mb-4" />
            <h2 className="text-xl font-bold text-gray-800 mb-2">CASA</h2>
            <p className="text-gray-600 pb-5">
            Engomaria e Lvandaria Remodelação e Decoração Limpeza Instalação e Reparação Jardinagem
            </p>
          </div>

          {/* Card 2 */}
          <div className="bg-white p-6  rounded-lg shadow-lg relative text-center max-md:mb-8">
            <div className="w-[62px] h-[62px] absolute -top-4 right-2 font-bebasneuepro bg-themered text-white uppercase text-sm font-bold  rounded-full text-center flex flex-col items-center justify-center leading-none">
              <span>Oferta</span> <span className='text-3xl leading-[0.7em]'>25€</span>
            </div>
            <img src={animal} alt="Casa" className=" mx-auto mb-4" />
            <h2 className="text-xl font-bold text-gray-800 mb-2">Animais</h2>
            <p className="text-gray-600 pb-5">
            Banho a Cães <br />
            Tosquia a Cães

            </p>
          </div>

          {/* Card 3 */}
          <div className="bg-white p-6  rounded-lg shadow-lg relative text-center max-md:mb-8">
            <div className="w-[62px] h-[62px] absolute -top-4 right-2 font-bebasneuepro bg-themered text-white uppercase text-sm font-bold  rounded-full text-center flex flex-col items-center justify-center leading-none">
              <span>Oferta</span> <span className='text-3xl leading-[0.7em]'>25€</span>
            </div>
            <img src={messagem} alt="Casa" className=" mx-auto mb-4" />
            <h2 className="text-xl font-bold text-gray-800 mb-2">Bem-estar</h2>
            <p className="text-gray-600 pb-5">
            Massagem Manicure Pedicure Nutricionista Personal Trainer
            </p>
          </div>

          {/* Card 4 */}
          <div className="bg-white p-6  rounded-lg shadow-lg relative text-center max-md:mb-8">
            <div className="w-[62px] h-[62px] absolute -top-4 right-2 font-bebasneuepro bg-themered text-white uppercase text-sm font-bold  rounded-full text-center flex flex-col items-center justify-center leading-none">
              <span>Oferta</span> <span className='text-3xl leading-[0.7em]'>25€</span>
            </div>
            <img src={empresas} alt="Casa" className=" mx-auto mb-4" />
            <h2 className="text-xl font-bold text-gray-800 mb-2">Empresas </h2>
            <p className="text-gray-600 pb-5">
            Limpezas de escritórios Limpeza de Bares Limpeza de Restaurantes
            </p>
          </div>
        </div>

        <div className=' flex justify-center pb-4 mt-4 lg:mt-12'><button onClick={scrollToTop} className='flex items-center justify-between w-[250px] lg:w-[350px] bg-[#9fbe80] hover:bg-[#86ad5e] text-center rounded-full px-4 py-3 text-white font-bebasneuepro font-semibold text-3xl  '><span></span> <span>RECEBER 25€ OFERTA
        </span> <img src={arrowcta} alt="" className='w-[15px]  lg:mr-5' /></button></div>

       
        
    </div>
  )
}

export default Service